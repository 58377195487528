import React from "react"
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import SectionWrap from "../../../components/sectionWrap"
import Introtext from "../../../components/introtext"
import Headline from "../../../components/headline"
import * as ffsStyles from "../../../components/sectionWrap/sectionWrap.module.css"
import ContentmoduleAbs from "../../../components/contentmoduleAbs"
import {AnchorLink} from "gatsby-plugin-anchor-links"
// Background-Images
import firstBackgroundImage from "../../../images/greyback.jpg"
import secondBackgroundImage from "../../../images/whiteback.jpg"
import sgBackgroundImage from "../../../images/greyback.jpg"
// Object-Images
import firstObjectImage from "../../../images/projekte/arag-beratungsapp/landing/arag-app-landing.png"
import secondObjectImage from "../../../images/projekte/bje-playbook/landing/playbook-landing.png"
import sgObjectImage from "../../../images/projekte/bje-smarter-gallery/bje-smarter-gallery.png"


const SalesApps = function (props, ref) {
  var counter = 1
  const data = [
    {
      logo: "/logos/logo-arag.svg",
      titel: "ARAG Beratungsapp",
      text: "Digitale Verkaufsunterstützung für die Vertriebsmannschaft der ARAG Versicherung.",
      slug: "/projekte/sales-apps/arag-beratungsapp",
      objectImage: firstObjectImage,
      bgImage: firstBackgroundImage,
    },
    {
      logo: "/logos/logo-busch-jaeger-bunt.svg",
      titel: "Busch-Jaeger Playbook",
      text: "Kundenbesuche und Außendienst-Jahresgespräche optimal begleitet",
      slug: "/projekte/sales-apps/busch-jaeger-playbook",
      objectImage: secondObjectImage,
      bgImage: secondBackgroundImage,
    },
    {
      logo: "/logos/logo-busch-jaeger-bunt.svg",
      titel: "Tools für die Smarter Gallery",
      text: "Reservierungstool und NFC-App für den Showroom von Busch-Jaeger.",
      slug: "/projekte/sales-apps/smarter-gallery",
      objectImage: sgObjectImage,
      bgImage: sgBackgroundImage,
    },    
  ]

  return (
    <Layout>
      <SEO
        title="Sales Apps"
        description="Vertriebs-Apps zur Unterstützung für den Außendienst von Q:marketing"
      />

      <div className="page-container-offset">
        {/* 
          head
        */}
        <div className="container-fluid">
          <SectionWrap id="abc" bgCol={"#fff"} className="height50 my-5">
            <div className={`row ${ffsStyles.vcenterRowContent}`}>
              {/* left */}
              <div className={`col-12 col-md-8 px10`}>
                <Headline
                  titel="Sales Apps & Tools"
                  showCta={false}
                  skyhead={false}
                  text={<>Mit den von uns konzipierten und entwickelten Sales Apps professionalisieren unsere Kunden die Verkaufsgespräche ihres Außendienstes. Die Apps geben den Gesprächen Struktur und erleichtern die Aufnahme aller relevanten Kundeninformationen bis zum Vertragsabschluss. Darüber hinaus programmieren wir zugeschnittene Anwendungen nach Ihren Anforderungen.<p className="pt-2"><AnchorLink to="/projekte/sales-apps/#beispiele" title="↓ Beispiele" className="" /></p></>}
                />
              </div>
            </div>
          </SectionWrap>
        </div>

        <div id="beispiele"></div>        

        {data.map(e => {
          counter++
          var result = counter % 2 ? true : false
          
          return (
            <div className="container-fluid">
              <SectionWrap bgImg={e.bgImage} bgCol={"#fff"} className="height80 projektdetailkachel">
                <div className={`row ${ffsStyles.vcenterRowContent} bg-q-grey-mobile`}>
                  <div className={`col-12 mx-0 px-0`}>
                    {/* Vordergrund */}
                    <ContentmoduleAbs>
                      <div className={`row ${ffsStyles.vcenterRowContent}`}>
                        <div className={`col-md-6 mx-0 px-0 vorschaubild ${result ? "order-md-last" : ""}`}>
                          <img src={e.logo} className="d-md-none xyz logopic" />
                          <Link to={e.slug}><img src={e.objectImage} style={{ objectFit: "contain", pointerEvents: "visible" }} /></Link>
                        </div>
                        <div className={`col-md-6 mx-0 px-0 textpart ${result ? "order-md-first" : ""}`}>
                          <Introtext
                            skypic={e.logo}
                            skyhead={false}
                            titel={e.titel}
                            text={e.text}
                            linklabel="Mehr erfahren"
                            link={e.slug}
                          />
                        </div>
                      </div>
                    </ContentmoduleAbs>
                  </div>
                </div>
              </SectionWrap>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default SalesApps
